$font_updates: true;
// Enable Appointment Booking Reskin 2019
$ab_skin_v2: true;

$gnav-category-link-letter-spacing: -0.05em;
$typography-headline--xl-letter-spacing: -0.05em;
$typography-headline--l-letter-spacing: -0.05em;
$typography-headline--m-letter-spacing: -0.05em;

$cr22: true;

$cr24: true;
